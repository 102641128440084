var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Container, Graphics, Text, TextStyle } from "pixi.js";
import Game from "../starter";
import { CONSTANTS, Helpers } from "lama-games-engine";
class WinningLinesContainer extends Container {
}
class SymbolsContainer extends Container {
}
export class WinningLines {
    constructor(lines) {
        this._container = new WinningLinesContainer();
        this.symbolsContainer = new SymbolsContainer();
        this.symbols = [];
        this.SIZE = window.PLATFORM.isMobile ? window.PLATFORM.isPortrait ? 160 : 100 : 80;
        // this.drawLines(lines)
        this.registerEvents();
        this.draw();
    }
    draw() {
        const HEIGHT = this.SIZE;
        //@ts-ignore
        const reelsView = Game.Engine.Navigator.currentScreen.reelsContainer.reelsView;
        //@ts-ignore
        const scale = Game.Engine.Navigator.currentScreen.scale;
        const bounds = reelsView.getBounds();
        const position = reelsView.getGlobalPosition();
        let offset = 10;
        if (window.PLATFORM.isMobile) {
            offset = 0;
            if (window.PLATFORM.isPortrait) {
                offset = -20;
            }
        }
        const destination = {
            x: (position.x / scale) + ((bounds.width / 2) / scale),
            y: (position.y / scale) + (bounds.height / scale) - (offset / scale),
        };
        const graph = new Graphics();
        graph.beginFill('#FF0000', 0);
        graph.drawRect(0, 0, reelsView.width, HEIGHT);
        this._container.pivot.set(reelsView.width / 2, HEIGHT / 2);
        this._container.position.set(destination.x, destination.y);
        this._container.addChild(graph);
    }
    drawLine(line) {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeout(this.timeout);
            this.clear();
            return new Promise(resolve => {
                this.setSymbols(line.symbol, line.count)
                    .setPrefix(line.line === 'scarabei' ? 'Scarabei' : `Line ${line.line}`)
                    .setSuffix(line.amount.toFixed(2))
                    .render();
                this.timeout = setTimeout(resolve, 1000);
            });
        });
    }
    drawLines(lines) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const line of lines) {
                yield this.drawLine(line);
            }
            // this.clear()
        });
    }
    registerEvents() {
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GAME_MODE_CHANGE, this.onModeChange.bind(this));
    }
    onModeChange({ mode }) {
        if (mode === 'idle') {
            this.clear();
        }
    }
    clear() {
        clearTimeout(this.timeout);
        this.symbols = [];
        this._container.removeChild(this.suffix);
        this._container.removeChild(this.prefix);
        this.symbolsContainer.removeChildren();
    }
    render() {
        this.setPositions();
        return this;
    }
    setPositions() {
        const destination = this._container.getGlobalPosition();
        this.symbolsContainer.pivot.set(this.symbolsContainer.width / 2, 0);
        this._container.pivot.set(this._container.width / 2, this._container.height / 2);
        this.symbolsContainer.position.set(this._container.width / 2, 0);
        this.setSymbolsPositions();
        this.prefix.position.set(this.symbolsContainer.x - this.prefix.width - (this.symbolsContainer.width / 2), (this._container.height / 2) - (this.prefix.height / 2) + 4);
        this.suffix.position.set(this.symbolsContainer.x + (this.symbolsContainer.width / 2), (this._container.height / 2) - (this.suffix.height / 2) + 4);
    }
    setSymbolsPositions() {
        this.symbols.forEach((symbol, index) => {
            if (index === 0) {
                return;
            }
            const prevSymbol = this.symbolsContainer.getChildAt(index - 1);
            symbol.position.set(prevSymbol.x + this.SIZE, prevSymbol.y);
        });
        this.symbolsContainer.pivot.set(this.symbolsContainer.width / 2, 0);
    }
    addSymbol(symbolKey) {
        const symbol = Game.Engine.SETTINGS.GAME_SYMBOLS_MAP[symbolKey];
        const image = Helpers.animatedSpriteFromFrames(`/textures/animations/${symbol}.json`);
        const size = this.SIZE;
        image.width = size;
        image.height = size;
        this.symbols.push(image);
        this.symbolsContainer.addChild(image);
    }
    setSymbols(symbolKey, count) {
        const symbols = Array(count).fill(symbolKey);
        symbols.forEach(symbol => {
            this.addSymbol(symbol);
        });
        this._container.addChild(this.symbolsContainer);
        return this;
    }
    setPrefix(text) {
        const textControl = new Text(text, this.getTextStyles());
        this.prefix = textControl;
        this._container.addChild(textControl);
        return this;
    }
    setSuffix(text = '0.40') {
        const textControl = new Text(text, this.getTextStyles());
        this.suffix = textControl;
        this._container.addChild(textControl);
        return this;
    }
    getTextStyles() {
        return new TextStyle({
            fontFamily: 'Belgrano',
            fontSize: window.PLATFORM.isMobile ? window.PLATFORM.isPortrait ? 72 : 48 : 36,
            // fill: 0x4b8f0b,
            fill: window.PLATFORM.isMobile ? 0xFFFFFF : 0x63452A,
            fontWeight: 'bold',
            strokeThickness: 3,
            stroke: window.PLATFORM.isMobile ? 0x63452A : 0xFFFFFF,
            dropShadow: true,
            dropShadowAlpha: 0.6,
            dropShadowAngle: 90,
            dropShadowBlur: 4,
            dropShadowDistance: 4,
            dropShadowColor: "#000000",
            trim: true
        });
    }
    get container() {
        return this._container;
    }
}
