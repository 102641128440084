import { CONSTANTS, Helpers, Reel } from 'lama-games-engine';
import { Container, NineSlicePlane, Texture } from 'pixi.js';
import Game from '../starter';
import { MultipliersContainer, RectangleMask, ReelsViewContainer } from './Containers';
export class ReelsContainer extends Container {
    constructor() {
        super();
        this.reelsView = new ReelsViewContainer();
        this.multipliersView = new MultipliersContainer();
        this.multipliers = [];
        this.multiplierContainers = [];
        this.zIndex = 1000;
        this.reelsView.name = "reelsview";
        this.name = "reelscontainer";
        this
            .loadStageView()
            .createReels();
        // Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.BASE_EVENTS.BALANCE_UPDATED, this.onWin.bind(this))
        // Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GAME_MODE_CHANGE, this.onModeChange.bind(this))
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.BASE_EVENTS.LOADED_ANIMATED_SCENE, this.onAnimatedSceneLoaded.bind(this));
        this.scene.name = "scene";
    }
    onAnimatedSceneLoaded() {
        if (window.PLATFORM.isMobile)
            return;
        const animatedTexture = Helpers.animatedSpriteFromFrames('/textures/scene-animation.json');
        this.scene.removeChildren();
        this.scene = animatedTexture;
        this.scene.loop = true;
        this.scene.animationSpeed = 0.2;
        // this.scene.scale.set(2)
        this.scene.play();
        this.removeChild(this.scene);
        this.addChild(this.scene);
        this.swapChildren(this.scene, this.reelsView);
    }
    // onModeChange ({mode}: {mode: GameMode}) {
    //   if (mode === 'idle') {
    //     this.scene.gotoAndStop(0)
    //   }
    // }
    //
    // onWin (data: AfterSpinResult) {
    //   if (Boolean(data.win)) {
    //     this.scene.play()
    //   }
    //
    // }
    getStageBackgroundKey() {
        if (window.PLATFORM.isLandscape && window.PLATFORM.isMobile) {
            return 'scene-landscape';
        }
        if (!window.PLATFORM.isMobile) {
            return 'scene-desktop';
        }
        return "scene";
    }
    loadStageView() {
        this.texture = new Texture(Helpers.getAsset(this.getStageBackgroundKey()));
        const scene = new NineSlicePlane(this.texture, this.texture.width / 2, this.texture.height / 2, this.texture.width / 2, this.texture.height / 2);
        this.scene = scene;
        scene.width = this.texture.width;
        scene.height = this.texture.height;
        this.addChild(scene);
        return this;
        // const animatedTexture = Helpers.animatedSpriteFromFrames('/textures/scene-animation.json')
        //
        // this.scene = animatedTexture
        // this.scene.loop = true
        // this.scene.animationSpeed = 0.2
        //
        // this.scene.play()
        // // scene.width = texture.width
        // // scene.height = texture.height
        //
        // this.addChild(this.scene);
        // return this;
    }
    getReelSize() {
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            return (this.scene.height / Game.Engine.SETTINGS.GAME_REELS) + 70;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            return this.scene.width / Game.Engine.SETTINGS.GAME_REELS;
        }
        if (this.scene.height < this.scene.width) {
            return ((this.scene.height) / Game.Engine.SETTINGS.GAME_REELS) + 110;
        }
        return ((this.scene.width) / Game.Engine.SETTINGS.GAME_REELS) + 110;
    }
    createReels() {
        this.addChild(this.reelsView);
        const size = this.getReelSize();
        const rWidth = this.scene.width;
        const rHeight = size * Game.Engine.SETTINGS.GAME_SYMBOLS_PER_REEL;
        // const rHeight = this.height;
        for (let i = 0; i < Number(Game.Engine.SETTINGS.GAME_REELS); i++) {
            const reel = new Reel(i, rWidth, rHeight);
            Game.Engine.REELS.push(reel);
        }
        Game.Engine.REELS.forEach(reel => {
            this.reelsView.addChild(reel.container);
            // reel.presentDividers();
        });
        this.reelsView.scale.set(0.62);
        this.reelsView.position.x = this.scene.width / 2 - this.reelsView.width / 2;
        this.reelsView.position.y += 220;
        const mask = new RectangleMask(rWidth, rHeight);
        this.reelsView.addChild(mask);
        this.reelsView.mask = mask;
        return this;
    }
    setMultipliers(data) { }
    clearMultipliers() { }
    getMultiplier(x, y) { }
    resize() {
        this.texture = new Texture(Helpers.getAsset(this.getStageBackgroundKey()));
        this.scene.texture = this.texture;
        this.scene.leftWidth = this.texture.width / 2;
        this.scene.topHeight = this.texture.height / 2;
        this.scene.rightWidth = this.texture.width / 2;
        this.scene.bottomHeight = this.texture.height / 2;
        this.scene.width = this.texture.width;
        this.scene.height = this.texture.height;
        // const size = this.getReelSize();
        // const rWidth = this.scene.width;
        // const rHeight = size * Game.Engine.SETTINGS.GAME_SYMBOLS_PER_REEL;
        // for (let i = 0; i < Number(Game.Engine.SETTINGS.GAME_REELS); i++) {
        //   const reel = Game.Engine.REELS[i];
        //   reel.setOffset(i);
        //   reel.setSize(rWidth, rHeight);
        //   reel.setContainerSize(i, rWidth);
        //   reel.setDividerSize(rHeight);
        // }
        this.reelsView.position.x = this.scene.width / 2 - this.reelsView.width / 2;
        // if (this.reelsView.mask as any) {
        //   (this.reelsView.mask as any).width = this.scene.width;
        //   (this.reelsView.mask as any).height = this.scene.height;
        // }
    }
}
