import { gsap } from 'gsap';
import { Power1 } from 'gsap/gsap-core';
import { Container, Graphics, Text } from "pixi.js";
import { Helpers } from "lama-games-engine";
const defaults = {
    sprite: 'not-found.json',
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    zIndex: 0,
    visible: true
};
export class BonusMultiplier {
    constructor(config) {
        this.config = Object.assign(Object.assign({}, defaults), config);
        this.scale = window.PLATFORM.isMobile ? 3 : 1;
        this
            .initContainer()
            .initSprite();
        this.timeline = gsap.timeline({
            repeat: 0,
            onComplete: () => {
                console.info(`[BONUS] animation completed`);
            }
        });
    }
    initContainer() {
        this.container = new Container();
        this.container.width = this.config.width;
        this.container.height = this.config.height;
        this.container.zIndex = this.config.zIndex;
        this.container.pivot = {
            x: this.config.width / 2,
            y: this.config.height / 2
        };
        this.container.scale = { x: 1, y: 1 };
        this.setPosition(this.config.x, this.config.y);
        return this;
    }
    initSprite() {
        this.animatedSprite = Helpers.animatedSpriteFromFrames(this.config.sprite);
        this.animatedSprite.anchor.set(0.5);
        this.animatedSprite.x = this.config.width / 2;
        this.animatedSprite.y = this.config.height / 2;
        this.animatedSprite.loop = false;
        this.animatedSprite.animationSpeed = 0.5;
        this.animatedSprite.play();
        const scale = Math.min(this.config.width / this.animatedSprite.width, this.config.height / this.animatedSprite.height);
        this.animatedSprite.scale.x = this.animatedSprite.scale.y = (scale * this.scale);
        this.container.addChild(this.animatedSprite);
        return this;
    }
    initMultiplyContainer(multiplier) {
        this.multiplyContainer = new Container();
        const graph = new Graphics();
        graph.beginFill(0xFFFFFF);
        graph.lineStyle({
            color: 0xBD664B,
            width: 5,
            native: true
        });
        graph.alpha = 1;
        const containerPoint = this.animatedSprite.getLocalBounds();
        graph.drawCircle((containerPoint.width / 2), (containerPoint.height / 2), window.PLATFORM.isMobile ? 16 * 5 : 16);
        const text = new Text(`x${multiplier}`, {
            fontFamily: 'Belgrano',
            fontSize: window.PLATFORM.isMobile ? 148 : 48,
            // fill: 0x4b8f0b,
            fill: 0x0716e8,
            fontWeight: 'bold',
            strokeThickness: 3,
            stroke: 0xFFFFFF,
            dropShadow: true,
            dropShadowAlpha: 0.6,
            dropShadowAngle: 90,
            dropShadowBlur: 4,
            dropShadowDistance: 4,
            dropShadowColor: "#000000",
            trim: true
        });
        text.x = ((containerPoint.width / 2) - (text.width / 2));
        text.y = (containerPoint.height / 2) - (text.height / 2);
        // text.scale.s
        // this.multiplyContainer.addChild(graph)
        this.multiplyContainer.addChild(text);
        this.container.addChild(this.multiplyContainer);
    }
    appendTo(container) {
        container.addChild(this.container);
        return this;
    }
    setPosition(x, y) {
        this.container.position.set(x, y);
        return this;
    }
    getWidth() {
        return this.container.width;
    }
    getHeight() {
        return this.container.height;
    }
    animate(destination, multiplier = 5) {
        return new Promise(resolve => {
            const duration = 1;
            const x = this.container.x;
            const move = gsap.timeline({
                repeat: 0,
                onComplete: () => {
                    console.info(`[BONUS] move completed`);
                }
            }).add(gsap.to(this.container, {
                duration: duration,
                ease: Power1.easeInOut,
                y: destination.y, // + this.animatedSprite.width / 2,
                x: destination.x, // + this.animatedSprite.height / 2
            }));
            //   .add(
            //   gsap.timeline({repeat: 1, yoyo: true})
            //     .to(this.container, {
            //       duration: duration / 2,
            //       ease: Power1.easeIn,
            //       x: x + 80
            //     }),
            //   "<"
            // );
            const scale = gsap.timeline({
                repeat: 0,
                onComplete: () => {
                    console.info(`[BONUS] scale completed`);
                }
            }).add(gsap.to(this.container.scale, {
                duration: duration / 2,
                ease: Power1.easeIn,
                x: 2,
                y: 2
            })).add(gsap.to(this.container.scale, {
                duration: duration / 2,
                ease: Power1.easeOut,
                x: 1,
                y: 1
            }));
            const rotate = gsap.timeline({
                repeat: 1,
                yoyo: true,
                onComplete: () => {
                    console.info(`[BONUS] rotate completed`);
                    this.initMultiplyContainer(multiplier);
                    resolve(null);
                }
            }).to(this.container, {
                duration: duration / 2,
                ease: Power1.easeInOut,
                angle: -20
            });
            this.timeline
                .add(move)
                .add(scale, "<")
                .add(rotate, "<");
        });
    }
}
