export const options = {
    enabled: true,
    filters: [
    // new DropShadowFilter({
    //   color: 0x000000,
    //   alpha: 0.3,
    //   blur: 2,
    //   offset: {x: 4, y: 4},
    // })
    ]
};
export const labelStyles = {
    fontFamily: 'Montserrat',
    fontSize: 55,
    "fill": '#ffef5d',
    stroke: "#a43321",
    strokeThickness: 10,
    trim: true
};
