import { Helpers } from 'lama-games-engine';
import { NineSlicePlane } from 'pixi.js';
import { AbstractButton } from './AbstractButton';
export class NineSliceButton extends AbstractButton {
    render() {
        this._container.removeChildren();
        this.setCursor();
        const background = this.getBackground();
        this._container.addChild(background);
        const label = this.getLabel();
        if (label) {
            label.zIndex = 10;
            this._container.addChild(label);
        }
        this
            .centerPivot()
            .addFilters();
    }
    getBackground() {
        const background = Helpers.getSpriteFromTextureURL(`field-left.png`);
        const slicePlane = new NineSlicePlane(background.texture, 50, 50, 50, 50);
        slicePlane.width = this.options.width;
        slicePlane.height = this.options.height;
        slicePlane.zIndex = 0;
        return slicePlane;
    }
    centerPivot() {
        this._container.pivot.set(this.options.width / 2, this.options.height / 2);
        return this;
    }
}
