var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Helpers, Modal } from 'lama-games-engine';
import { Color, Container, Graphics, Point, SimpleRope, Text } from 'pixi.js';
import Game from "../starter";
import { ZoomBlurFilter } from '@pixi/filter-zoom-blur';
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};
class ModalContentWrapper extends Container {
}
class BaseWin {
    title(title, width) {
        const radius = this.titleRadius();
        const text = new Text(title, {
            fontFamily: 'Montserrat',
            fontSize: 150,
            fontWeight: 'bold',
            fill: '#eaece3',
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 1,
            dropShadowAngle: 90,
            dropShadowBlur: 0,
            dropShadowColor: '#0a7cec',
            dropShadowDistance: 10
        });
        const points = [];
        for (let i = 0; i < 20; i++) {
            const y = i < 10 ? i : 20 - i;
            points.push(new Point(i * text.width / 20, y * 10));
        }
        const newPoints = [];
        for (let i = 0; i <= 20; i++) {
            const point = polarToCartesian(width / 2, 0, radius, ((30 * i) / 20) - 15);
            const pivotPoint = new Point(point.x, point.y);
            newPoints.push(pivotPoint);
        }
        return new SimpleRope(text.texture, newPoints);
    }
    titleRadius() {
        return 1400;
    }
    message(message) {
        const text = new Text(message, {
            fontFamily: 'Montserrat',
            fontSize: (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) ? 160 : 80,
            fontWeight: 'bold',
            align: 'center',
            fill: '#ffdc50',
            stroke: '#830f12',
            strokeThickness: 6,
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 0.6,
            dropShadowAngle: 120,
            dropShadowBlur: 6,
            dropShadowDistance: 6,
            dropShadowColor: "#000000",
        });
        text.anchor.set(0.5);
        text.position.set(text.width / 2, (text.height / 2));
        return text;
    }
}
class TenFreeSpinsStars extends BaseWin {
    stars() {
        const container = new Container();
        const star = Helpers.getSpriteFromTextureURL('star.png');
        star.scale.set(0.6);
        star.anchor.set(0.5);
        star.position.set(star.width / 2, star.height / 2);
        container.addChild(star);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 25;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`mega-win-desktop.png`);
    }
    title() {
        return super.title('FREE SPINS', this.background().width);
    }
    message(message) {
        return super.message('10');
    }
}
class HugeWin extends BaseWin {
    stars() {
        const container = new Container();
        const star = Helpers.getSpriteFromTextureURL('star.png');
        star.scale.set(0.6);
        star.anchor.set(0.5);
        star.position.set(star.width / 2, star.height / 2);
        container.addChild(star);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 25;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`mega-win-desktop.png`);
    }
    title() {
        return super.title('HUGE WIN', this.background().width);
    }
}
class MegaWin extends BaseWin {
    stars() {
        const container = new Container();
        const first = Helpers.getSpriteFromTextureURL('star.png');
        first.scale.set(0.8);
        first.anchor.set(0.5);
        first.angle = -6;
        first.position.set(first.width / 2, first.height / 2);
        container.addChild(first);
        const second = Helpers.getSpriteFromTextureURL('star.png');
        second.scale.set(0.8);
        second.anchor.set(0.5);
        second.angle = 6;
        second.position.set(second.width / 2, second.height / 2);
        second.position.x += first.width + 50;
        container.addChild(second);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 40;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`mega-win-desktop.png`);
    }
    title() {
        return super.title('MEGA WIN', this.background().width);
    }
}
class EpicWin extends BaseWin {
    stars() {
        const container = new Container();
        const first = Helpers.getSpriteFromTextureURL('star.png');
        first.scale.set(0.8);
        first.anchor.set(0.5);
        first.angle = -12;
        first.position.set(first.width / 2, first.height / 2);
        container.addChild(first);
        const second = Helpers.getSpriteFromTextureURL('star.png');
        second.scale.set(1);
        second.anchor.set(0.5);
        second.position.set(second.width / 2, second.height / 2);
        second.position.x = first.position.x + first.width + 20;
        second.position.y -= 85;
        container.addChild(second);
        const third = Helpers.getSpriteFromTextureURL('star.png');
        third.scale.set(0.8);
        third.anchor.set(0.5);
        third.angle = 12;
        third.position.set(third.width / 2, third.height / 2);
        third.position.x = second.position.x + second.width - 30;
        container.addChild(third);
        container.pivot.set(container.width / 2, container.height / 2);
        container.position.set(container.width / 2, container.height / 2);
        return container;
    }
    starsOffset() {
        return 100;
    }
    background() {
        return Helpers.getSpriteFromTextureURL(`mega-win-desktop.png`);
    }
    title() {
        return super.title('EPIC WIN', this.background().width);
    }
}
class WinFactory {
    static make(type) {
        return new this.map[type];
    }
}
WinFactory.map = {
    "huge": HugeWin,
    "mega": MegaWin,
    "epic": EpicWin,
    "10-free-spins": TenFreeSpinsStars
};
export class WinModal {
    constructor(type, options) {
        this.type = type;
        this.options = options;
        this.container = new ModalContentWrapper();
        this.modal = new Modal(this.options);
        this.win = WinFactory.make(this.type);
    }
    static presentModal(type, options) {
        return (new WinModal(type, options));
    }
    show() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalContent = this.modalContent();
            const modalGlow = this.modalGlow();
            modalContent.zIndex = 1000;
            modalGlow.zIndex = 100;
            this.container.sortableChildren = true;
            this.container.addChild(modalGlow);
            this.container.addChild(modalContent);
            modalContent.position.set(this.container.x + (this.container.width / 2) - (modalContent.width / 2), this.container.y + (this.container.height / 2) - (modalContent.height / 2));
            this.container.pivot.set((this.container.width / 2), 0);
            yield this.modal
                .addContentChild(this.container)
                .show();
        });
    }
    modalContent() {
        var _a;
        const container = new Container();
        const { innerWidth: width, innerHeight: height } = window;
        const background = this.win.background();
        background.scale.set((window.PLATFORM.isMobile && window.PLATFORM.isPortrait) ? 2 : 1);
        const stars = this.win.stars();
        const title = this.win.title();
        const titleRadius = this.win.titleRadius();
        const message = this.win.message(`$ ${Game.Engine.Application.gameData.lastWin.toFixed(2)}`);
        container.addChild(background);
        // this.container.addChild(stars);
        // this.container.addChild(title);
        container.addChild(message);
        stars.position.set(background.width / 2, background.position.y + this.win.starsOffset());
        title.position.y = titleRadius
            + background.y
            + background.height
            + 50;
        message.position.set(container.width / 2, ((_a = title === null || title === void 0 ? void 0 : title.position.y) !== null && _a !== void 0 ? _a : 0) - titleRadius - ((window.PLATFORM.isMobile && window.PLATFORM.isPortrait) ? 300 : 200));
        background.position.set(container.position.x + (container.width / 2), container.position.y + (container.height / 2));
        background.anchor.set(0.5);
        return container;
    }
    modalGlow() {
        const container = new Container();
        const background = this.win.background();
        const { innerWidth: width, innerHeight: height } = window;
        const scale = window.PLATFORM.isMobile && window.PLATFORM.isPortrait ? 2 : 1;
        background.scale.set(scale);
        const size = new Graphics();
        size.beginFill(new Color('FF0000'));
        size.drawRect(0, 0, window.PLATFORM.isMobile && window.PLATFORM.isPortrait ? background.width : width, window.PLATFORM.isMobile && window.PLATFORM.isPortrait ? background.height : height);
        size.endFill();
        size.alpha = 0;
        size.scale.set(scale);
        container.addChild(size);
        container.addChild(background);
        background.position.set(container.position.x + (container.width / 2), container.position.y + (container.height / 2));
        background.anchor.set(0.5);
        const point = new Point((container.position.x + (container.width / 2)) * this.options.scale, container.position.y + (container.height / 2) * this.options.scale);
        container.filters = [
            new ZoomBlurFilter({
                strength: 0.3,
                center: point,
                innerRadius: 0,
                radius: -1,
            })
        ];
        container.alpha = 0.75;
        return container;
    }
    hide() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modal.hide();
        });
    }
}
