export const SymbolsMap = {
    1: "watermelon",
    2: "melon",
    3: "orange",
    4: "pomegranate",
    5: "grape",
    6: "fig",
    7: "pyramid",
    8: "scarabei",
};
const SoundsMap = JSON.parse(process.env.GAME_SOUNDS_MAP || "");
export const SoundOptions = {
    playSound: {
        asset: SoundsMap["playSound"] || "",
        options: {
            volume: 0.5,
            loop: true,
        },
    },
    idleSound: {
        asset: SoundsMap["idleSound"] || "",
        options: {
            volume: 0.3,
            loop: true
        },
    },
    wheelsSound: {
        asset: SoundsMap["wheelsSound"] || "",
        options: {
            volume: 0.9,
            start: 0.3,
            end: 2.4,
            speed: 1,
        },
    },
    singleWheelSound: {
        asset: SoundsMap["singleWheelSound"] || "",
        options: {
            volume: 0.9,
            start: 0,
            end: 1.75,
            speed: 0.85,
        },
    },
    sliceSound: {
        asset: SoundsMap["sliceSound"] || "",
        options: {
            volume: 1,
            start: 0,
            end: 1,
        },
    },
    katanaSound: {
        asset: SoundsMap["katanaSound"] || "",
        options: {
            volume: 0,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    winSound: {
        asset: SoundsMap["winSound"] || "",
        options: {
            volume: 1,
            speed: 0.6,
        },
    },
    wildSound: {
        asset: SoundsMap["wildSound"] || "",
        options: {
            volume: 0,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    scatterSound1: {
        asset: SoundsMap["scatterSound1"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    scatterSound2: {
        asset: SoundsMap["scatterSound2"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    scatterSound3: {
        asset: SoundsMap["scatterSound3"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
};
